import { connect } from "react-redux"
import { useState } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import { get_opinion, clearDetail } from "redux/actions/restaurants/restaurants";
import { useEffect } from "react";
import { Typewriter } from "react-simple-typewriter"
import eaztBrand from "assets/img/eazt logo.png";
import axios from "axios";
import { Geo } from "react-bootstrap-icons";
import ClipLoader from "react-spinners/ClipLoader"
function CardChatFavorites({
  showModal,
  closeModal,
  get_opinion,
  name,
  detail,
  resume,
  rating,
  reviews,
  clearDetail,
  photoUrl,
  url,
  place_id

}) {
  const [isFinished, setIsFinished] = useState(false);
  const [conclusion, setConclusion] = useState('');
  const [loading, setLoading] = useState(false);
  const [ItsFavorited, setFavorite] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    rating: '',
    reviews: '',
    photoUrl: ''

  })
  const cleanName = (name) => {
    return name
      .normalize("NFD") // Descompone caracteres acentuados (á -> a + ́)
      .replace(/[\u0300-\u036f]/g, "") // Elimina los caracteres diacríticos
      .replace(/[^a-zA-Z0-9\s]/g, ""); // Elimina caracteres especiales excepto letras, números y espacios
  };




  







  useEffect(() => {
    if (showModal) {
      const fetchOpinion = async () => {
        name = cleanName(name); // Limpia el nombre antes de enviarlo
        await get_opinion(name, rating, reviews, resume); // Obtén la respuesta completa

      }
      fetchOpinion(); // Ejecuta la función de manera asíncrona
    }
  }, [showModal, get_opinion, name]); // Se ejecuta cuando showModal cambia
  useEffect(() => {
    if (detail) {
      // Convertir a minúsculas y quitar tildes
      const normalizedDetail = detail.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      // Extraer el texto después de "conclusión:"
      const conclusionMatch = normalizedDetail.match(/conclusión.\s*(.*)/);
      if (conclusionMatch) {
        setConclusion(conclusionMatch[1].trim());
      }
    }
  }, [detail]); // Dependencia en detail

  const handleCloseModal = () => {
    setConclusion('');   // Reiniciar la conclusión
    setIsFinished(false); // Reiniciar el estado de escritura
    closeModal();         // Cerrar el modal
    clearDetail();        // Limpia el estado de detail (Redux)
  };

  if (!showModal) {
    return null;
  }


  return (
    <>
      <div
        className={`modal-overlay ${showModal ? "show" : ""}`}
        onClick={handleCloseModal}
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="card_chat px-5">
            {/* Botón de cerrar (X) */}
            <button
              className="close-button"
              onClick={handleCloseModal}
              aria-label="Cerrar modal"
            >
              ×
            </button>

            <div className="body align-items-center  text-center">
              <img src={eaztBrand} className="mt-5 mx-auto" width={100} alt="Brand Logo" />

              {detail ? (
                <p className="mt-2 text-white text-justify">
                  {conclusion && <h3 className="text-white">{conclusion}</h3>}{" "}
                  {/* Mostrar conclusión */}

                  {!isFinished ? (
                    <Typewriter
                      words={[detail]}
                      cursor
                      cursorStyle="_"
                      typeSpeed={1} // Velocidad de escritura
                      delaySpeed={1500} // Pausa entre palabras
                      onDone={() => setIsFinished(true)} // Establecer el estado a true al finalizar
                    />
                  ) : (
                    detail // Mostrar el texto completo
                  )}
                </p>
              ) : (
                <p className="text-white mt-5">Cargando opinión...</p>
              )}

              <div className="icons mt-2">



                <a href={url} target="_blank" rel="noopener noreferrer">
                  <button className="btn-chat">
                    <Geo className="icon" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>


  );
}

const mapStateToProps = state => ({
  detail: state.restaurants.detail ? state.restaurants.detail.response : null,


})
export default connect(mapStateToProps, {
  get_opinion,
  clearDetail,

})(CardChatFavorites)