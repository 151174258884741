import { connect } from "react-redux"
import { useState, useEffect } from "react";
import eaztLoad  from "assets/gif/carga.gif";

function Layout({ children }) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }, []);
  
    return (
      <div style={{ position: "relative", zIndex: 2 }}>
        {isLoading ? (
          <div className="loading-container">
            <img src={eaztLoad} alt="Cargando..." className="loading-gif" />
          </div>
        ) : (
          children
        )}
      </div>
    );

}

const mapStateToProp = state => ({ //llama las variables de redux que quiero llamar


})
export default connect(mapStateToProp, {



})(Layout)