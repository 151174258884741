import axios from "axios"

import {
    GET_DETAIL_SUCCESS,
    GET_DETAIL_FAIL,
    GET_FAVORITES_FAIL,
    GET_FAVORITES_SUCCESS,
    CLEAR_DETAIL
} from "./types"


function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}





export const get_opinion = (name, rating, reviews) => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json' // Asegura que el backend reciba JSON
        }
    };

    const body = JSON.stringify({
        name: name || "Sin nombre",
        rating: rating || 0,
        reviews: reviews || "Sin reviews"
    });



    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/restaurants/ai` ,body,config);

        if (res.status === 200) {
            dispatch({
                type: GET_DETAIL_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_DETAIL_FAIL,
            });
        }
    } catch (err) {
        console.error('Error en get_opinion:', err.response ? err.response.data : err.message);
        dispatch({
            type: GET_DETAIL_FAIL,
        });
    }
}

export const clearDetail = () => dispatch => {
    dispatch({
        type: CLEAR_DETAIL
    });
};

export const get_favorites = () => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`

       
        },

    };


    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/restaurants/getFavorites` ,config);

        if (res.status === 200) {
            dispatch({
                type: GET_FAVORITES_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_FAVORITES_FAIL,
            });
        }
    } catch (err) {
        dispatch({
            type: GET_FAVORITES_FAIL,
        });
    }
}

