import { connect } from "react-redux"
import { useState } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import CardChatFavorites from "./CardChatFavorites";
import { StarFill, GeoAltFill } from "react-bootstrap-icons";
import { motion } from "framer-motion";
import flecha from "assets/img/flechaDerecha.png";
import flechaN from "assets/img/flechaDerechaNegra.png";
import ClipLoader from "react-spinners/ClipLoader"
import axios from "axios";
import { HeartFill, XLg, Trash } from "react-bootstrap-icons";
function CardFavorites({ name, types, photoUrl, rating, reviews, distance, resume, url, place_id, onFavoriteRemoved }) {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const getCSRFToken = () => {
        const name = 'csrftoken';
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return '';
    };
    const removeFavorite = async () => {
        const isConfirmed = window.confirm("¿Estás seguro de que deseas eliminar este favorito?");

        if (!isConfirmed) return; // Si el usuario cancela, no hace nada

        const config = {
            headers: {
                'Accept': 'application/json',
                'X-CSRFToken': getCSRFToken(),
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json'
            },
        };

        const data = { place_id }; // Solo necesitas enviar el place_id

        try {
            setLoading(true);
            const res = await axios.delete(
                `${process.env.REACT_APP_API_URL}/api/restaurants/removeFavorite`,
                { data, ...config } // Enviar el body correctamente en DELETE
            );
            setLoading(false);

            onFavoriteRemoved();
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.status === 404) {
                alert('Error al eliminar favorito: no encontrado');
            } else {
                console.error(error);
            }
        }
    };



    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <>
            <motion.div initial={{ y: -70, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} viewport={{ once: true }}  >
                <article onClick={openModal} className="fav-article-wrapper my-3 mx-auto"
                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >

                    {/* Contenedor de la imagen */}
                    <div className="container-project rounded-lg">
                        <div className="fav-project-hover  row-card-favorite ">
                            <img src={isHovered ? flecha : flechaN} width={30} alt="Flecha" />
                        </div>
                        <img src={photoUrl} alt={name} className="fav-img" />

                        <div className="project-title ms-3">{name}</div>

                        {/* Icono de eliminar */}
                        <button
                            className="fav-btn-delete"
                            onClick={(e) => {
                                e.stopPropagation(); // Evita que el click llegue al <article>
                                removeFavorite();
                            }}
                        >
                            <XLg className="fav-icon-trash" />
                        </button>
                    </div>
                </article>

                <CardChatFavorites
                    showModal={showModal}
                    closeModal={closeModal}
                    name={name}
                    rating={rating}
                    reviews={reviews}
                    resume={resume}
                    photoUrl={photoUrl}
                    url={url}
                    place_id={place_id}
                />

            </motion.div>

        </>

    );
}

const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(CardFavorites)