import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import eazt from "assets/img/logo largo.png";
import eaztFlecha from "assets/img/flecha.png";
import { PersonCircle } from 'react-bootstrap-icons';
import { logout } from 'redux/actions/auth/auth'
import { useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'
const NavbarApp = ({ logout, user }) => {

  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    navigate('/login')
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="custom-navbar py-3">
        <Container>
          {/* Botón hamburguesa */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="text-nav" />

          {/* Logo a la izquierda */}
          <button
            onClick={() => window.history.back()}
            style={{ background: 'none', border: 'none', padding: 0 }}
          >
            <img
              src={eaztFlecha}
              width="40px"
              className="d-inline-block align-top img-fluid"
              alt="Eazt Logo"
            />
          </button>

          {/* Contenido colapsable */}
          <Navbar.Collapse id="responsive-navbar-nav ">
            {/* Menú centrado */}
            <Nav className="mx-auto text-center d-flex align-items-center justify-content-center">
              <Nav.Link href="/about" className="text-nav" style={{ marginLeft: "40px" }}>Nosotros</Nav.Link>

              {/* Logo centrado */}
              <Navbar.Brand href="/" className="mx-4">
                <img
                  src={eazt}
                  width="100px" 
                  className="d-inline-block align-top img-fluid"
                  alt="Eazt Logo"
                />
              </Navbar.Brand>

              <Nav.Link href="/contact" className="text-nav">Contacto</Nav.Link>
            </Nav>


            <div className="dropdown cssbuttons-io-button px-3 py-2">
              <button className="dropdown-toggle" id="dropdown-basic" aria-haspopup="true" aria-expanded="false" onClick={toggleDropdown}>
                <PersonCircle color='#fff' size={35} className='mx-1' />
                {user ? user.first_name : 'loading'}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdown-basic">
                <a className="dropdown-item" href="/favorites">Favoritos</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" onClick={() => handleLogout()}>Log Out</a>
              </div>
            </div>

          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  );
};
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {
  logout

})(NavbarApp)  