import { connect } from "react-redux"
import { useState } from 'react'
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CardChat from "components/restaurants/CardChat";
import { StarFill, GeoAltFill } from "react-bootstrap-icons";
import { motion } from "framer-motion";
import flecha from "assets/img/flechaDerecha.png";
import flechaN from "assets/img/flechaDerechaNegra.png";

import defaultImage from 'assets/img/foto-default.jpg'; // Ruta de la imagen por defecto
function CardRestaurants({ name, types, photoUrl, rating, reviews, distance, resume, url, place_id }) {
    const [showModal, setShowModal] = useState(false);

    const [validImage, setValidImage] = useState(true);

    useEffect(() => {
        // Verificamos si la URL de la imagen está vacía o no es válida
        if (!photoUrl || photoUrl.trim() === "") {
            setValidImage(false);
        }
    }, [photoUrl]);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <>
            <motion.div initial={{ y: -70, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} viewport={{ once: true }}  >
                <article onClick={openModal} className="article-wrapper my-3 " onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>

                    <div className="container-project rounded-lg">
                        <div className="project-hover  row-card-restaurant ">
                            <img src={isHovered ? flecha : flechaN} width={30} alt="Flecha" />
                        </div>
                        <img
                            src={validImage ? photoUrl : defaultImage} // Si la URL es válida, muestra la imagen, sino, la por defecto
                            alt={name}
                            style={{
                                width: '100%',
                                height: '200px',
                                objectFit: "cover",
                                borderRadius: "10px",
                            }}
                        />

                        <div className="project-title text-nowrap ms-3 ">{name}

                        </div>
                        <div className="project-info">





                            {/* Agrupar direction y rating */}
                            <div className="d-flex align-items-center mt-2">
                                <div className="direction d-flex align-items-center">
                                    <p className="mb-0 ms-3 d-flex align-items-center">
                                        <GeoAltFill className="mx-1" color="#db0000" /> {distance} km
                                    </p>
                                </div>
                                <div className="rating d-flex align-items-center">
                                    <p className="mb-0 ms-3 text-black d-flex align-items-center">
                                        <StarFill color="#e7da0a" /> {rating}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>



                </article>
                <CardChat
                    showModal={showModal}
                    closeModal={closeModal}
                    name={name}
                    rating={rating}
                    reviews={reviews}
                    resume={resume}
                    photoUrl={photoUrl}
                    place_id={place_id}
                    url={url} />

            </motion.div>

        </>

    );
}

const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(CardRestaurants)