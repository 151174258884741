import { connect } from "react-redux"
import { useState } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from "react-bootstrap";
import CardChat from "components/restaurants/CardChat";

function CardPhone({ name, description, icon }) {

    return (
        <>

            <div className="card-phone mx-auto  p-3" style={{ backgroundColor: '#0F1223' }}>
                {/* Contenedor para el icono y el título */}
                <div className="d-flex align-items-center">
                    {/* Icono a la izquierda */}
                    <img src={icon} alt="Icon" style={{ width: "40px" }} />

                    {/* Título a la derecha del icono */}
                    <h4 className="fw-bold ">{name}</h4>
                </div>

                {/* Descripción como párrafo */}
                <div className="text-center mt-3">
                    <p className="p-phone">{description}</p>
                </div>
            </div>
        </>

    );
}

const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(CardPhone)